.square{
    background-color: white;
    border: 1px solid #999;
    font-size: 24px;
    width: 100px;
    height: 100px;
    margin-right: -1px;
    margin-top: -1px;
    margin-bottom: 0px;
    padding: 0;
    text-align: center;
    float: left;
}