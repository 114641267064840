body {
  background-color: #2c3e50;
  color: #fff;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}
  
ol, ul {
  margin: 0;
  padding: 0;
}